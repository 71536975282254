// Import GSAP and ScrollTrigger
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Import Lenis
import Lenis from '@studio-freight/lenis';

// Import Swup and Swup debug plugin
import Swup from 'swup';
import SwupDebugPlugin from '@swup/debug-plugin';


document.addEventListener('DOMContentLoaded', () => {
	const menuButton = document.getElementById('menuButton');
	const gnav = document.getElementById('gnav');
	let menuState = false;
	let scrollPos;

	menuButton.addEventListener('click', () => {
			menuButton.classList.toggle('show');
			gnav.classList.toggle('show');
			if (!menuState) {
					scrollPos = window.scrollY;
					document.body.classList.add('fixed');
					document.body.style.top = `-${scrollPos}px`;
					menuState = true;
			} else {
					document.body.classList.remove('fixed');
					document.body.style.top = '0';
					window.scrollTo(0, scrollPos);
					menuState = false;
			}
	});

	Array.from(gnav.querySelectorAll('a')).forEach(link => {
			link.addEventListener('click', closeMenu);
	});

	window.addEventListener('load', handleResize);
	window.addEventListener('resize', handleResize);

	document.addEventListener('click', (event) => {
			if (!gnav.contains(event.target) && !menuButton.contains(event.target) && menuState) {
					closeMenu();
			}
	});

	function closeMenu() {
			menuButton.classList.remove('show');
			gnav.classList.remove('show');
			document.body.classList.remove('fixed');
			document.body.style.top = '0';
			window.scrollTo(0, scrollPos);
			menuState = false;
	}

	function handleResize() {
			if (window.innerWidth >= 768) {
					document.body.classList.remove('fixed');
					document.body.style.top = '0';
			}
	}
});



let startPos = 0;
let winScrollTop = 0;
const header = document.querySelector('header');
let menuState = false;
window.addEventListener('scroll', function () {
	winScrollTop = window.scrollY;
	if (winScrollTop !== 0) {
		header.classList.add('is-scroll');
	} else {
		header.classList.remove('is-scroll');
	}
	if (winScrollTop >= startPos) {
		if (winScrollTop >= 200 && !menuState) {
			header.classList.add('is-down');
		}
	} else {
		header.classList.remove('is-down');
	}
	startPos = winScrollTop;
});

// lenis

const lenis = new Lenis();
function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}
requestAnimationFrame(raf);

// Lenis smooth scrolling for anchor links, apply offset only if window width is less than 768px
function setupLenisSmoothScroll() {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (event) {
      event.preventDefault();
      const targetId = this.getAttribute('href').slice(1); 
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        let offset = 0;
        if (window.innerWidth < 768) {
          const headerHeight = document.querySelector('header').offsetHeight; // ヘッダーの高さを取得
          offset = -headerHeight; // 768px未満の場合にのみヘッダー分のオフセットを追加
        }
        lenis.scrollTo(targetElement, { offset: offset });
      }
    });
  });

  // Apply Lenis scroll behavior to 'no-swup' links as well
  document.querySelectorAll('a.no-swup[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (event) {
      event.preventDefault();
      const targetId = this.getAttribute('href').slice(1); 
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        let offset = 0;
        if (window.innerWidth < 768) {
          const headerHeight = document.querySelector('header').offsetHeight; // ヘッダーの高さを取得
          offset = -headerHeight; // 768px未満の場合にのみヘッダー分のオフセットを追加
        }
        lenis.scrollTo(targetElement, { offset: offset });
      }
    });
  });

	// #pagetopにクリックイベントを追加
	const pageTop = document.getElementById('pagetop');
	if (pageTop) {
		pageTop.addEventListener('click', () => {
			lenis.scrollTo(0);
		});
	} 
}

// 初回ロード時にLenisのスムーズスクロールをセットアップ
document.addEventListener('DOMContentLoaded', () => {
  setupLenisSmoothScroll();
});

// SWUP initialization
const swup = new Swup({
  plugins: [new SwupDebugPlugin()],
  linkSelector: 'a:not(.no-swup)'
});

// Function to update body class based on main's data-pagename
function updateBodyClass() {
  const mainElement = document.querySelector('main');
  if (mainElement) {
    const pageName = mainElement.dataset.pagename;
    // Update body tag's class
    document.body.className = pageName ? `${pageName}` : '';
  }
}

// Function to add 'is-ready' class when all resources are loaded
function addIsReadyClass() {
  // For first page load
  if (document.readyState === 'complete') {
    document.body.classList.add('is-ready');
  } else {
    window.addEventListener('load', () => {
      document.body.classList.add('is-ready');
    });
  }
}

// Function to add 'is-ready' class after Swup content replacement
function addIsReadyClassAfterTransition() {
  document.body.classList.add('is-ready');
}

// Function to remove 'is-ready' class (called on visit:start)
function removeIsReadyClass() {
  document.body.classList.remove('is-ready');
}

// SWUP initialization function (formerly init)
function swupinit() {
  // Your existing init logic here
  // ...

  // Add body class update
  updateBodyClass();

  // Add 'is-ready' class when all resources are loaded
  addIsReadyClass();
	// mailSent();
}

// Use visit:start hook to update body class and remove 'is-ready'
swup.hooks.on('visit:start', () => {
  removeIsReadyClass();
  updateBodyClass();
});

// Add 'is-ready' class after the new content has finished animating in
swup.hooks.on('animation:in:end', () => {
  addIsReadyClassAfterTransition();
});

// Run swupinit on DOMContentLoaded
if (document.readyState === 'complete') {
  swupinit();
} else {
  document.addEventListener('DOMContentLoaded', () => swupinit());
};

// SWUP hooks
swup.hooks.on('page:view', () => {
	swupinit();
});

swup.hooks.on("visit:start", () => {
  ScrollTrigger.getAll().forEach((t) => t.kill(false));
  ScrollTrigger.refresh();
});

// Add hook for updating body class after content is replaced
swup.hooks.on('content:replace', () => {
  updateBodyClass();
	setupLenisSmoothScroll();
});

let formSubmitted = false;

document.querySelector("form.wpcf7-form").addEventListener("submit", function(event) {
    if (formSubmitted) {
        event.preventDefault(); // フォームがすでに送信された場合、再送信を防ぐ
        return;
    }
    formSubmitted = true; // 最初の送信時にフラグを立てる
});
